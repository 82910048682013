import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

const number = {
    fontSize: 24,
    fontFamily: 'default',
    color: 'secondary.main',
    fontWeight: 'medium',
};

const image = {
    height: 600,
    my: 8,
    cursor: 'pointer',
};

function Overview() {
    return (
        <Box
            component="section"
            sx={{ display: 'flex', overflow: 'hidden' }}
        >
            <Container
                sx={{
                    mt: 10,
                    mb: 15,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        top: -180,
                        opacity: 0.7,
                    }}
                />
                <Typography variant="h2" marked="center" component="h2" sx={{ mb: 14 }}>
                    Overview
                </Typography>

                <Typography variant="h5" align="center">
                    - FedOps was designed and implemented to enable FL lifecycle operations management by extending the existing MLOps concept.
                    <br></br>
                    <br></br>
                    - FedOps is to manage the entire FL process of creating a global model by deploying and training the local model created in the experimental environment.
                </Typography>
                <Box
                    component="img"
                    src="https://gachon-cclab.github.io/docs/img/FedOps_Overview.PNG"
                    alt="curvy lines"
                    sx={
                        image
                    }
                />

                <Typography variant="h4" marked="center" component="h4" sx={{ mb: 14 }}>
                    FedOps Detail
                </Typography>
                <Typography variant="h5" align="center">
                    1. Easily application of data and models to the FL environment  (FL Scalize)<br></br>

                    2. Continuously check and manage FL Client/Server state (Manager)<br></br>

                    3. Contribution Evaluation and Client Selection to give Incentive to Client (CE/CS & BCFL)<br></br>

                    4. Easily deploy FL Client/Server and perform periodic FL rounds (CI/CD/CFL)<br></br>

                    5. FL Client/Server monitoring dashboard for FL Lifecycle Visualization (Monitoring)<br></br>
                </Typography>
                <Box
                    component="img"
                    src="https://gachon-cclab.github.io/docs/img/architecture.PNG"
                    alt="curvy lines"
                    sx={{
                        height: 550,
                        my: 8,
                        cursor: 'pointer',
                    }}
                />
            </Container>
        </Box >
    );
}

export default Overview;