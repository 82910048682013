import React from 'react';
import { Typography } from '@mui/material';
import Header from '../components/common/Header';
import ContentWrapper from '../components/common/ContentWrapper';
import { Box, Card, Fade } from '../../node_modules/@mui/material/index';
import Lottie from 'lottie-react';
import { ReactMarkdown } from '../../node_modules/react-markdown/lib/react-markdown';
import remarkGfm from '../../node_modules/remark-gfm/index';
import Why_FedOps from './Why_FedOps'
import JoinCommunity from './JoinCommunity'
import Overview from './Overview';
import { Container, Button, Paper, Grid } from '@mui/material';

// import * as animationData from 'https://assets4.lottiefiles.com/packages/lf20_3ziDZbeGax.json';

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// };

const image = {
  height: 200,
  my: 8,
  cursor: 'pointer',
};

const MainPage = () => {


  return (
    <React.Fragment>
      <Header />
      <ContentWrapper>
        <Box sx={{ mt: 2 }}>
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography color="#2196f3" variant="h2" component="h1" gutterBottom align="left" marginTop="5px" style={{ lineHeight: '1.2' }}>
                  FedOps:
                </Typography>
                <Typography variant="h2" component="h1" gutterBottom align="left" marginTop="5px" style={{ lineHeight: '1.2' }}>
                  Federated Learning
                </Typography>
                <Typography color="#2196f3" variant="h2" component="h1" gutterBottom align="left" marginTop="5px" style={{ lineHeight: '1.2' }}>
                  Life Cycle
                </Typography>
                <Typography variant="h2" component="h1" gutterBottom align="left" marginTop="5px" style={{ lineHeight: '1.2' }}>
                  Management Platform
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <img className='FedOps_logo' alt="FedOps logo" src="/fedops/img/fedops_logo_with_letter.png" style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
              </Grid>
            </Grid>
            <Box my={4}>
              <Typography variant="h5" component="h2" gutterBottom>
                FedOps is a comprehensive platform designed to facilitate operations in federated learning systems, addressing the challenges of heterogeneity management. This platform integrates a set of tools and technologies that enable efficient deployment, monitoring, and management of federated learning projects across diverse and distributed environments.
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                Key Features
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ padding: '16px' }}>
                    <Typography variant="h6" gutterBottom>
                      Heterogeneity Management
                    </Typography>
                    <Typography>
                      Efficiently manages the diverse computational and data resources in federated learning environments.
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ padding: '16px' }}>
                    <Typography variant="h6" gutterBottom>
                      Scalable Deployment
                    </Typography>
                    <Typography>
                      Supports scalable and flexible deployment options to meet the demands of various project sizes.
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ padding: '16px' }}>
                    <Typography variant="h6" gutterBottom>
                      Real-time Monitoring
                    </Typography>
                    <Typography>
                      Offers real-time monitoring tools for tracking the performance and progress of federated learning models.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Button variant="contained" color="primary">
              Learn More
            </Button>
          </Container>
        </Box>
        <Overview />
        <Why_FedOps />
        <JoinCommunity />
      </ContentWrapper>
    </React.Fragment>
  );
};

export default MainPage;
