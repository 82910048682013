import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, Box, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { logout } from '../../modules/user';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import logo from './../../img/FedOps_logo_without_letter.png'
import { ThemeProvider, createTheme } from '@mui/material/styles';

const pages = [
  { name: 'Task', path: '/fedops/task', external: false },
  { name: 'Document', path: 'https://gachon-cclab.github.io/', external: true },
  { name: 'Fedops Silo', path: 'https://github.com/gachon-CCLab/FedOps-Silo', external: true }
];

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      text: '#979797'
    },
    secondary: {
      main: '#80d8ff',
      AppBar: '#80d8ff'
    }
  },
});

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static" sx={{ borderBottom: 2, borderColor: 'divider', color: "secondary.AppBar" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters={false}>
            <img src={logo} alt="Logo" style={{ height: '30px', paddingRight: '10px' }} />
            <Typography
              variant="h5"
              component="a"
              href="/fedops"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                color: '#3c3c3c',
                textDecoration: 'none',
              }}
            >
              FedOps Ver1.1
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) =>
                page.external ? (
                  <Button
                    key={page.name}
                    component="a" // a 태그로 변경
                    href={page.path} // href 속성 사용
                    target="_blank" // 새 탭에서 열기
                    rel="noopener noreferrer" // 보안을 위한 속성
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: 'primary.text',
                      display: 'block',
                      '&:hover': {
                        color: 'secondary.main',
                      }
                    }}
                  >
                    {page.name}
                  </Button>
                ) : (
                  <Button
                    key={page.name}
                    component={Link}
                    to={page.path}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: 'primary.text',
                      display: 'block',
                      '&:hover': {
                        color: 'secondary.main',
                      }
                    }}
                  >
                    {page.name}
                  </Button>
                )
              )}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {user ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Chip label={user.organization} sx={{ mr: 2 }} />
                  <Chip label={user.username} sx={{ mr: 2 }} />
                  <Chip label={user.lastLogintime} sx={{ mr: 2 }} />
                  <Button variant="contained" onClick={onLogout} color="secondary">
                    Logout
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/fedops/login"
                    color="secondary"
                  >
                    Login
                  </Button>
                </div>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
