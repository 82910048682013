import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import {
  Typography,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Chart from '../components/monitoring/Chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
);

const createDataChartTwoVar = (
  log,
  label1,
  label2,
  field1,
  field2,
  title,
  xAxisField,
) => {
  const datasets = [
    {
      label: label1,
      data: log.map((data) => data[field1]),
      borderColor: 'red',
      backgroundColor: 'rgba(255, 0, 0, 0.2)',
    },
    {
      label: label2,
      data: log.map((data) => data[field2]),
      borderColor: 'blue',
      backgroundColor: 'rgba(0, 0, 255, 0.2)',
    },
  ];
  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: title },
    },
    interaction: { mode: 'index', intersect: false },
  };
  return { labels: log.map((data) => data[xAxisField]), datasets, options };
};

const createDataChartOneVar = (log, label, field, title, xAxisField) => {
  const datasets = [
    {
      label,
      data: log.map((data) => data[field]),
      borderColor: 'red',
      backgroundColor: 'rgba(255, 0, 0, 0.2)',
    },
  ];
  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: title },
    },
    interaction: { mode: 'index', intersect: false },
  };
  return { labels: log.map((data) => data[xAxisField]), datasets, options };
};

const useSocket = (title, setLatestModelVersion) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io('https://ccl.gachon.ac.kr/', {
      cors: {
        origin: "*"
      }
    });
    newSocket.on('connect', () => {
      console.log('Socket connected:', newSocket.connected);
      newSocket.emit('getModelVersion', title);
    });
    newSocket.on('returnModelVersion', (modelVersion) => {
      console.log('Received model version:', modelVersion);
      setLatestModelVersion(modelVersion);
    });

    const handleBeforeUnload = () => newSocket.close();
    window.addEventListener('beforeunload', handleBeforeUnload);
    setSocket(newSocket);

    return () => {
      newSocket.close();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [title]);

  return socket;
};

const useCreateChart = (
  socket,
  title,
  selectedModelVersion,
  selectedMacAddress,
  setServerData,
  setClientData,
) => {
  useEffect(() => {
    if (socket) {
      socket.emit('get_monitoring', {
        _id: title,
        modelVersion: selectedModelVersion,
        macAddress: selectedMacAddress,
      });
      socket.on(
        'response_monitoring',
        ({ trainResultLog, testResultLog, basicSystemLog, globalModelLog }) => {
          const chartData1 = createDataChartOneVar(
            globalModelLog,
            'Loss',
            'gl_loss',
            'Global Model Loss',
            'round',
          );
          const chartData2 = createDataChartOneVar(
            globalModelLog,
            'Accuracy',
            'gl_accuracy',
            'Global Model Accuracy',
            'round',
          );
          const chartData3 = createDataChartOneVar(
            globalModelLog,
            'Time',
            'run_time_by_round',
            'Elapsed Time by Communication Round (seconds)',
            'round',
          );
          const chartData4 = createDataChartTwoVar(
            trainResultLog,
            'Train Loss',
            'Train Accuracy',
            'train_loss',
            'train_accuracy',
            'Train Performance',
            'round',
          );
          const chartData5 = createDataChartTwoVar(
            testResultLog,
            'Test Loss',
            'Test Accuracy',
            'test_loss',
            'test_accuracy',
            'Test Performance',
            'round',
          );
          const chartData6 = createDataChartTwoVar(
            basicSystemLog,
            'Network Sent',
            'Network Received',
            'network_sent',
            'network_recv',
            'Network Traffic (bytes)',
            'runtime',
          );
          const chartData7 = createDataChartOneVar(
            basicSystemLog,
            'Disk (%)',
            'disk_utilization',
            'Disk Utilization (%)',
            'runtime',
          );
          const chartData8 = createDataChartOneVar(
            basicSystemLog,
            'CPU Threads',
            'cpu_threads',
            'Process CPU Threads In Use',
            'runtime',
          );
          const chartData9 = createDataChartOneVar(
            basicSystemLog,
            'Available Memory',
            'memory_availableMB',
            'Process Memory Available (non-swap) (MB)',
            'runtime',
          );
          const chartData10 = createDataChartOneVar(
            basicSystemLog,
            'Memory (%)',
            'memory_percent',
            'Process Memory In Use (non-swap) (%)',
            'runtime',
          );
          const chartData11 = createDataChartOneVar(
            basicSystemLog,
            'Memory (MB)',
            'memory_rssMB',
            'Process Memory In Use (non-swap) (MB)',
            'runtime',
          );
          const chartData12 = createDataChartOneVar(
            basicSystemLog,
            'Memory (%)',
            'memory_utilization',
            'System Memory Utilization (%)',
            'runtime',
          );
          const chartData13 = createDataChartOneVar(
            basicSystemLog,
            'CPU (%)',
            'cpu_utilization',
            'Process CPU Utilization (%)',
            'runtime',
          );
          setServerData([
            chartData1,
            chartData2,
          ])
          setClientData([
            chartData3,
            chartData4,
            chartData5,
            chartData6,
            chartData7,
            chartData8,
            chartData9,
            chartData10,
            chartData11,
            chartData12,
            chartData13
          ]);
        },
      );
    }
  }, [socket, title, selectedModelVersion, selectedMacAddress]);
};

const MonitoringPage = () => {
  const { title } = useParams();
  const [clientData, setClientData] = useState([null, null, null]);
  const [serverData, setServerData] = useState([null, null, null]);
  const [latestModelVersion, setLatestModelVersion] = useState(null);
  const [selectedModelVersion, setSelectedModelVersion] = useState(''); // Initialize as empty string
  const [macAddresses, setMacAddresses] = useState([]);
  const [selectedMacAddress, setSelectedMacAddress] = useState(''); // Initialize as empty string

  const socket = useSocket(title, setLatestModelVersion);

  // When latestModelVersion is updated, set selectedModelVersion to latest
  useEffect(() => {
    if (latestModelVersion) {
      setSelectedModelVersion(latestModelVersion);
    }
  }, [latestModelVersion]);

  // When macAddresses are updated, set selectedMacAddress to first address
  useEffect(() => {
    if (macAddresses.length > 0) {
      setSelectedMacAddress(macAddresses[0]);
    }
  }, [macAddresses]);

  // Request MAC addresses when component mounts
  useEffect(() => {
    if (socket) {
      console.log('Requesting MAC addresses');
      console.log(title);
      socket.emit('getMacAddresses', { title: title });
    }
  }, [socket, title]);

  // Listen for MAC addresses
  useEffect(() => {
    if (socket) {
      socket.on('returnMacAddresses', (addresses) => {
        console.log('Received MAC addresses:', addresses);
        setMacAddresses(addresses);
      });

      return () => {
        socket.off('returnMacAddresses');
      };
    }
  }, [socket]);

  useCreateChart(socket, title, selectedModelVersion, selectedMacAddress, setServerData, setClientData);


  const handleModelVersionChange = (event) => {
    console.log('Changing model version to:', event.target.value);
    setSelectedModelVersion(event.target.value);
    // 선택한 모델 버전에 참여한 맥주소들을 불러온다.
    setMacAddresses(socket.emit('get_MAC_Addresses_by_modelVersion', { _id: title, modelVersion: event.target.value, }));
    setMacAddresses(socket.emit('get_MAC_Addresses_by_modelVersion', { title: title, modelVersion: event.target.value, }));
    socket.emit('get_monitoring', {
      _id: title,
      modelVersion: event.target.value,
      macAddress: selectedMacAddress,
    });

    socket.on('returnMacAddresses', (addresses) => {
      console.log('Received MAC addresses:', addresses);
      setMacAddresses(addresses);
    });
  };

  const handleMacAddressChange = (event) => {
    console.log('Changing MAC address to:', event.target.value);
    setSelectedMacAddress(event.target.value);
    socket.emit('get_monitoring', {
      _id: title,
      macAddress: event.target.value,
      modelVersion: selectedModelVersion,
    });
  };

  return (
    <>
      {latestModelVersion && (
        <FormControl variant="filled">
          <InputLabel>Model Version</InputLabel>
          <Select
            value={selectedModelVersion}
            onChange={handleModelVersionChange}
            sx={{ mb: 4, mr: 2 }}
          >
            {[...Array(latestModelVersion)].map((_, i) => (
              <MenuItem value={i + 1} key={i + 1}>{`Model ${i + 1}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Grid container spacing={1}>
        {serverData.map((chart, i) =>
          chart ? (
            <Grid item xs={6} key={i}>
              <Chart data={chart} options={chart.options} />
            </Grid>
          ) : null,
        )}
      </Grid>

      {macAddresses.length > 0 && (
        <FormControl variant="filled" sx={{ width: '20%' }}>
          <InputLabel>MAC Address</InputLabel>
          <Select
            value={selectedMacAddress}
            onChange={handleMacAddressChange}
            sx={{ mb: 4 }}
          >
            {macAddresses.map((macAddress, i) => (
              <MenuItem
                value={macAddress}
                key={i}
              >{`MAC: ${macAddress}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Grid container spacing={1}>
        {clientData.map((chart, i) =>
          chart ? (
            <Grid item xs={6} key={i}>
              <Chart data={chart} options={chart.options} />
            </Grid>
          ) : null,
        )}
      </Grid>
    </>
  );
};

export default MonitoringPage;
