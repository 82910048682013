import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography,
   Box, CircularProgress, Grid, Chip } from '@mui/material';
import { useTaskData } from '../../components/hooks/useTaskData';
import { TaskDevice } from '../../components/task/TaskDevice';
import { TaskRefresh } from '../../components/task/TaskRefresh';
import StartTrainButton from '../../components/task/StartTrainButton';
import TransitionAlerts from '../../components/common/TransitionAlerts';
import { Button } from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import { readTask, removeTask } from '../../modules/task';
import { setOriginalTask } from '../../modules/create';

const TaskDetailContent = ({title}) => {
  const { task } = useSelector(({ task }) => ({ task: task.task }));
  const { data, loading, requestData, socketRef, status } = useTaskData(title);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDeviceIndices, setSelectedDeviceIndices] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: '',
  });

  useEffect(() => {
    dispatch(readTask(title));

    socketRef.current.on('startTrainResponse', (response) => {
      const { status } = response;
      const message =
        status === 'Task started.'
          ? 'The task has started successfully.'
          : status;
      const severity = status === 'Task started.' ? 'success' : 'error';

      setAlert({ open: true, message, severity });
    });
  }, []);

  const handleSelect = (device, index) => {
    if (selectedDeviceIndices.includes(index)) {
      setSelectedDeviceIndices(
        selectedDeviceIndices.filter((item) => item !== index),
      );
    } else {
      setSelectedDeviceIndices([...selectedDeviceIndices, index]);
    }
  };

  const handleStartTrain = (selectedDeviceIndices, socketRef) => {
    const selectedDevices = selectedDeviceIndices.map(
      (index) => data[index].Device_mac,
    );
    socketRef.current.emit(
      'startTrain',
      {
        taskId: task.title,
        devices: selectedDevices,
        serverRepoAddr: task.serverRepoAddr,
      },
      (response) => {
        const { status } = response;
        const message =
          status === 'Task started.'
            ? 'The task has started successfully.'
            : status;
        const severity = status === 'Task started.' ? 'success' : 'error';

        setAlert({ open: true, message, severity });
      },
    );
  };

  // Task 삭제 핸들러, Delete 버튼을 누르면 실행
  const handleDelete = () => {
    dispatch(removeTask(title));
    navigate('/fedops/task', { state: { isEdit: true, editId: title } });
  }
  // Task 수정 핸들러, Modify 버튼을 누르면 실행
  const handleEdit = () => {
      dispatch(setOriginalTask(task));
      navigate('/fedops/task/create', { state: { isEdit: true, editId: title } });
  };

  try {
    console.log(task);
  } catch (err) {
    console.log(err);
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box whiteSpace="nowrap">
            {task && task.title && (
            <Typography gutterBottom variant="h6" component="div">
            Join FL Clients with Task ID <b>'{task.title}'</b>
            </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="flex-end">
          <TaskRefresh loading={loading} onRequestData={requestData} />
        </Grid>
      </Grid>

      {task && task.serverRepoAddr && (
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 1, color: 'text.secondary' }}
        >
          FL server will clone to
          <b>
            <br></br>'{task.serverRepoAddr}'
          </b>{' '}
          ...
        </Typography>
      )}

      {task && task.status && (
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 1, color: 'text.secondary' }}
        >
          Server Type
          <b>
            <br></br>'{task.tags}'
          </b>{' '}
        </Typography>
      )}

      {alert.open && (
        <TransitionAlerts alert={alert} setAlert={setAlert} sx={{ mt: 2 }} />
      )}

      {status && !loading && (
        <Chip label={status.status} variant="outlined" sx={{ mt: 2 }} />
      )}

      {/* <Divider sx={{ mt: 1, mb: 3 }} /> */}

      {loading ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '50vh' }}
        >
          <CircularProgress />
        </Grid>
      ) :  Array.isArray(data) ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
          {data.map((device, index) => (
            <TaskDevice
              key={index}
              device={device}
              index={index}
              isSelected={selectedDeviceIndices.includes(index)}
              onSelect={handleSelect}
            />
          ))}
        </Box>
      ) : (
        <Typography variant="body1" component="p">
          No data available.
        </Typography>
      )}

      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        sx={{ mt: 4 }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDelete}
          sx={{ mr: 2 }}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleEdit}
          sx={{ mr: 2 }}
        >
          Modify
        </Button>
        <StartTrainButton
          onClick={() => handleStartTrain(selectedDeviceIndices, socketRef)}
          selectedDevices={selectedDeviceIndices}
          disabled={selectedDeviceIndices.length === 0}
        />
      </Grid>
    </>
  );
};

export default TaskDetailContent;