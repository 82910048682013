import React from 'react';
import {
  useParams,
  Routes,
  Route,
} from 'react-router-dom';
import {
  Typography,
  Box,
  Divider,
} from '@mui/material';
import Header from '../components/common/Header';
import ContentWrapper from '../components/common/ContentWrapper';
import NavTabs from '../components/task/NavTabs';
import MonitoringPage from './MonitoringPage';
import TaskDetailContent from '../components/task/TaskDetailContent';
import ProtectedRoute from '../components/route/ProtectedRoute';
import GlobalModelPage from './GlobalModelPage';

const TaskDetailPage = () => {
  const { title } = useParams();
  return (
    <>
      <Header />
      <ContentWrapper>
        <Box sx={{ position: 'relative', right: 1, mr: 6, mb: 5 }}>
          <Typography gutterBottom variant="h5" component="div">
            Task Detail
          </Typography>

          <Divider sx={{ mb: 2 }} />

          <NavTabs title={title} />
          <Box sx={{ mt: 4 }}>
            <Routes>
              <Route path="/" element={<TaskDetailContent title={title} />} />
              <Route
                path="monitoring"
                element={
                  <ProtectedRoute component={MonitoringPage} title={title} />
                }
              />
              <Route
                path="global-model"
                element={
                  <ProtectedRoute component={GlobalModelPage} title={title} />
                }
              />
            </Routes>
          </Box>
        </Box>
      </ContentWrapper>
    </>
  );
};

export default TaskDetailPage;