import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listModels, resetModels } from '../modules/model.js';
import {
  Box,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from '@mui/material';

const GlobalModelPage = () => {
  const { title } = useParams();
  const dispatch = useDispatch();
  const { models } = useSelector((state) => state.model);

  useEffect(() => {
    dispatch(listModels(title));
    // Cleanup function
    return () => {
      dispatch(resetModels());
    };
  }, [dispatch, title]);

  if (!models) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (models.length === 0) {
    return <Typography variant="h6">No models found.</Typography>;
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Global Models
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Model Name</TableCell>
              <TableCell align="right"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model) => (
              <TableRow key={model.name}>
                <TableCell component="th" scope="row">
                  {model.name}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    href={model.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GlobalModelPage;
