import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as modelAPI from '../lib/api/model';
import { takeLatest } from 'redux-saga/effects';

// Action Types
const [LIST_MODELS, LIST_MODELS_SUCCESS, LIST_MODELS_FAILURE] =
  createRequestActionTypes('model/LIST_MODELS');

const RESET_MODELS = 'model/RESET_MODELS';

// Action Creators
export const listModels = createAction(LIST_MODELS, (title) => title);

export const resetModels = createAction(RESET_MODELS);

// Sagas
const listModelsSaga = createRequestSaga(LIST_MODELS, modelAPI.getModelList);
export function* modelSaga() {
  yield takeLatest(LIST_MODELS, listModelsSaga);
}

// Initial State
const initialState = {
  models: null,
  error: null,
};

// Reducer
const model = handleActions(
  {
    [LIST_MODELS_SUCCESS]: (state, { payload: models }) => ({
      ...state,
      models,
      error: null,
    }),
    [LIST_MODELS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      models: null,
      error,
    }),
    [RESET_MODELS]: (state) => ({
      ...state,
      models: null,
      error: null,
    }),
  },
  initialState,
);

export default model;
